import { useState } from 'react';
import { HttpService } from '../http/HttpService';
import "./WithdrawalDetails.css";
import spinner from '../assets/spinner.png';
import { getHttpService } from '..';


const WithdrawalDetails = (props) => {

const [progress, setProgress] = useState({pending: false, success: false, error: false, errorMessage: '', reference: null});

const onConfirmWithdrawal = (e) => {
    e.preventDefault();
    

    const {amount, currency, password, reference } = e.target.elements

    setProgress({pending: true, success: false, error: false, reference: null})

    let request = {
        confirmationCode: props.withdrawal.confirmationCode,
        password: password.value,
        reference: reference.value
    }

    console.log("Confirm withdrawal ", request)

    getHttpService().post("/pos/player/withdraw/", request).then((data) => {
        console.log("Confirmation response ", data)
        setProgress({pending: false, success: true, error: false, reference: request.reference})
        
    }).catch((e)=>{
        console.log("Error ", e);
        setProgress({pending: false, success: false, error: true, errorMessage: e})
    });
}

    return (
        <div className="card">
            <div className="card-header">
                <h5 className="card-category">Action</h5>
                <h3 className="card-title">Confirm Withdrawal</h3>
            </div>
            <div className="card-body">
                <form onSubmit={onConfirmWithdrawal}>
                    <div className="row d-flex justify-content-center">
                        <div className="col-sm-8 col-xs-12">

                            <div className="row d-flex justify-content-center">
                                    <div className="col-sm-8 col-xs-12">
                                        <div className="row">
                                            <h1 id="amountLabel" className="text-center">{props.withdrawal.amount} {props.withdrawal.currency}</h1>
                                        </div>
                                    </div>
                            </div>


                            {!progress.success && 
                                    <div>
                                        <div className="form-group">
                                                <label>Password <span className="form-text text-muted">Enter your personal password</span></label>
                                                <div className="row">
                                                        <div className="col-12">
                                                                <input id="password" type="password" className="form-control" autoComplete="off"/>
                                                        </div>
                                                </div>
                                                
                                        </div>

                                        <br/>
                                        <div className="form-group">
                                                <label>Reference <span className="form-text text-muted">Optional - you can enter a reference for this payment</span></label>
                                                <div className="row">
                                                        <div className="col-12">
                                                                <input id="reference" type="text" className="form-control" autoComplete="off"/>
                                                        </div>
                                                </div>
                                                
                                        </div>
                                    </div>
                            }


                            {progress.error && 
                                <div className="row d-flex justify-content-center">
                                    <div className="alert alert-danger alert-with-icon" data-notify="container">
                                            <span data-notify="icon" className="tim-icons icon-alert-circle-exc"></span>
                                            <span data-notify="message">The request failed. See error message below and make sure your input is correct.<br/><br/>  
                                            {progress.errorMessage} 
                                            </span>
                                    </div>
                                </div>
                            }                   
                                                 
                            {!progress.pending && !progress.success &&
                                <div className="row d-flex justify-content-center">
                                    <label id="amountInstructionsLabel" className="text-center">Confirm withdrawal before giving out any money</label>
                                    <input className="btn-primary btn-round confirmWithdrawButton nav-link active btn" type="submit" value="Confirm Withdrawal" />
        
                                </div>
                            }
                                
                            

                            {progress.pending &&
                                <div className="row d-flex justify-content-center">
                                    <img className="spinnerImage" src={spinner}/>
                                </div>
                            }


                            {progress.success && 
                                <div className="row d-flex justify-content-center">
                                    <div className="card card-stats col-md-6 col-sm-8 col-xs-12">
                                            <div className="card-body">
                                                    <div className="row">
                                                            <div className="col-2">
                                                                <div className="info-icon text-center icon-success">
                                                                        <i className="tim-icons icon-check-2"></i>
                                                                </div>
                                                            </div>
                                                            <div className="col-10">
                                                                <div className="numbers">
                                                                        <p className="card-category">Sucess</p>
                                                                        <h3 className="card-title">WITHDRAWAL CONFIRMED</h3>
                                                                </div>
                                                            </div>
                                                    </div>
                                            </div>
                                            
                                            {progress.reference &&
                                                    <div className="card-footer">
                                                            <hr/>
                                                            <div className="stats">
                                                                <i className="tim-icons icon-tag"></i> Reference: {progress.reference}
                                                            </div>
                                                    </div>
                                            }
                                    </div>
                                                            
                                </div>
                        }

                            
                                
                            
                        </div>
                    </div>
                </form>
            </div>
            <div className="card-footer">
            
            </div>
        </div>
    );

}

export default WithdrawalDetails;