import React, {useReducer, useState} from 'react';
import "./Kyc.css";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import spinner from '../../assets/spinner.png';
import { HttpService } from '../../http/HttpService';
import Player from '../../components/Player';
import { getHttpService } from '../..';

const Kyc = (props) => {

    const [term, setTerm] = useState();
    const [player, setPlayer] = useState({});
    const [progress, setProgress] = useState({pending: false, missing: false});
    const session = useSelector((state) => state.session);

    const dispatch = useDispatch();

    const onSubmitSearchForm = (e) => {
        e.preventDefault();
        const query = e.target.elements.query.value
        let request = {
            search: query
        }
        findPlayer(query);
    }

    const findPlayer = (query) => {
        let request = {
            search: query
        }
        setProgress({pending: true, missing: false})
        setPlayer({});

        getHttpService().post("/pos/player/find", request).then((data) => {
            setProgress({pending: false, missing: false})
            setPlayer(data);
            setTerm(query);

        }).catch((e)=>{
            console.log("Error ", e);
            setProgress({pending: false, missing: true})
        });
        
    }

    const reloadPlayer = () => {
        findPlayer(term);
    }

    
    return(
        <React.Fragment>
                <div className="wrapper wrapper-full-page ">        
                    <div className="full-page login-page ">
                        <div className="content">
                            <div className="container">
                                <div className="col-12">
                                    <Link className="nav-link text-primary" to="/"><i className="tim-icons icon-minimal-left"></i> Back to Dashboard</Link>
                                    <br/>
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <form onSubmit={onSubmitSearchForm}>
                                                    <label>Full Email or Phone Number <span className="form-text text-muted">Phone numbers must start with '+' and include country code, e.g. +639123456 or +4670123456</span></label>
                                                    <div className="form-group">
                                                        <input id="query" className="form-control" autoComplete="off"/>
                                                        
                                                    </div>
                                                    <input className="btn btn-fill btn-primary btn-round" type="submit" value="Find Player" />{progress.pending && <img className="spinnerImage" src={spinner}/>}
                                                </form>
                                            </div>
                                        </div>

                                        {progress.missing && <div className="col-12 text-center"><h3>Player not found</h3></div>}

                                        {player.userId && <Player player={player} reloadPlayer={reloadPlayer} settings={props.settings}/>} 

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
    )
}

export default Kyc;
