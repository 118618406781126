import React, {useReducer, useState} from 'react';
import "./Withdraw.css";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import spinner from '../../assets/spinner.png';
import { HttpService } from '../../http/HttpService';
import Player from '../../components/Player';
import PlayerDetails from '../../components/PlayerDetails';
import WithdrawalDetails from '../../components/WithdrawalDetails';
import { getHttpService } from '../..';

const Withdraw = (props) => {

    const [progress, setProgress] = useState({pending: false, error: false});
    const [withdrawal, setWithdrawal] = useState(null);
    const [player, setPlayer] = useState(null);


    const lookupConfirmationCode = (e) => {
        e.preventDefault();
        const query = e.target.elements.query.value
        setProgress({pending: true, error: false})
        setWithdrawal(null);
        setPlayer(null);

        getHttpService().get("/pos/player/withdraw/"+query).then((data) => {
            setPlayer({
                userId: data.userId,
                email: data.email,
                phone: data.phoneNumber,
                firstName: data.firstName,
                lastName: data.lastName,
                dateOfBirth: data.dateOfBirth,
                kycVerified: data.kycVerified,
                amount: data.amount,
                currency: data.currency,
                sameAffiliateId: data.sameAffiliateId
            });
            setProgress({pending: false, error: false})
            setWithdrawal(data);
            
        }).catch((e)=>{
            console.log("Error ", e);
            setProgress({pending: false, error: true})
        });
    }

    return(
        <React.Fragment>
                <div className="wrapper wrapper-full-page ">        
                    <div className="full-page login-page ">
                        <div className="content">
                            <div className="container">
                                <div className="col-12">
                                    <Link className="nav-link text-primary" to="/"><i className="tim-icons icon-minimal-left"></i> Back to Dashboard</Link>
                                    <br/>
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <form onSubmit={lookupConfirmationCode}>
                                                    <label>Withdraw Confirmation Code</label>
                                                    <div className="form-group">
                                                        <input id="query" className="form-control" autoComplete="off"/>
                                                        
                                                    </div>
                                                    <input className="btn btn-fill btn-primary btn-round" type="submit" value="Find Withdrawal" />{progress.pending && <img className="spinnerImage" src={spinner}/>}
                                                </form>
                                            </div>
                                        </div>
                                        {player && <PlayerDetails player={player} />} 

                                        {withdrawal && <WithdrawalDetails withdrawal={withdrawal} />} 

                                        {progress.error && <div className="col-12 text-center"><h3>Withdrawal not found</h3></div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
    )
}

export default Withdraw;
