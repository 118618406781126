import './bootstrap/css/black-dashboard.css';
import './bootstrap/css/nucleo-icons.css';
import './App.css';
import {useEffect, useState } from 'react';
import {Route, BrowserRouter as Switch, useHistory} from "react-router-dom";
import {HttpService} from "./http/HttpService";
import Login from './pages/login/Login';
import Dashboard from './pages/dashboard/Dashboard';

import {handleLoginResponse} from "./redux/sessionReducer";
import { useDispatch, useSelector } from 'react-redux';
import Topbar from './components/Topbar';
import Deposit from './pages/deposit/Deposit';
import Kyc from './pages/kyc/Kyc';
import Withdraw from './pages/withdraw/Withdraw';
import { getHttpService } from '.';


const App = () => {

    const [settings, setSettings] = useState({});

    const session = useSelector((state) => state.session);
    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {
        getSettings();
        checkedLoggedIn();
     },[]);

 
    const getSettings = () => {
        getHttpService().get("/public/config").then((data) => {
            console.log("Affiliate settings: ", data);
            setSettings(data);
        });
    }

    const checkedLoggedIn = () => {
        getHttpService().get("/pos/user").then((data) => {
            dispatch(handleLoginResponse(data));
        });
    }

    if (session.loggedIn == false) {
        return <Login settings={settings} />
    }

    return (
        <div>
            <Topbar />
            <Switch>
                <Route exact path="/"><Dashboard settings={settings}/></Route>
                <Route exact path="/player"><Kyc settings={settings}/></Route>
                <Route exact path="/deposit"><Deposit/></Route>
                <Route exact path="/withdraw"><Withdraw/></Route>
            </Switch>
        </div>
    );

}

export default App;
