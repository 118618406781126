import React, {useReducer} from 'react';
import "./Dashboard.css";
import Topbar from "../../components/Topbar";
import {HttpService} from "../../http/HttpService";
import {sessionReducer} from "../../redux/sessionReducer";
import { useSelector } from 'react-redux';
import {Link} from "react-router-dom";

const Dashboard = (props) => {

    const session = useSelector((state) => state.session);

    console.log("Dashboard Render - is logged in? ", session);
    console.log("Dashboard Render - settings ", props.settings);

    const renderKycPanel = () => {
        console.log("Render KYC. User / Enabled: ", session.user);
       if (session.user.kycEnabled) {
         return (
            <div className="card">
                <div className="card-header">
                    <h5 className="card-category">Action</h5>
                    <h3 className="card-title">Identity Verification</h3>
                </div>
                <div className="card-body">
                    <div className="row">
                    <div className="col-md-4">
                        <ul className="nav nav-pills nav-pills-primary flex-column">
                        <li className="nav-item">
                            <Link className="nav-link active" to='/player'>Identity Check</Link>
                        </li>
                        </ul>
                    </div>
                    <div className="col-md-8">
                        <div className="tab-content">
                        <div className="tab-pane active" id="link1">
                            Check that the player's identification matches the account and marked the player as verified him in the system.
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>)
       } else {
        <div/>
       }
    }


    return(
        <React.Fragment>
                <div className="wrapper wrapper-full-page ">        
                    <div className="full-page login-page ">
                        <div className="content">
                            <div className="container">

                            <div className="col-lg-8 col-md-8 ml-auto mr-auto">

                            <img className="dashLogo" src={props.settings.logoUrl} alt=""/>

                                {renderKycPanel()}

                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-category">Action</h5>
                                        <h3 className="card-title">Deposit</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                        <div className="col-md-4">
                                            <ul className="nav nav-pills nav-pills-primary flex-column">
                                            <li className="nav-item">
                                                <Link className="nav-link active" to='/player'>Player Deposit</Link>
                                            </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="tab-content">
                                            <div className="tab-pane active" id="link1">
                                                Verify ID and fund a player's account by a deposit made by the player. 
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-category">Action</h5>
                                        <h3 className="card-title">Withdraw</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                        <div className="col-md-4">
                                            <ul className="nav nav-pills nav-pills-primary flex-column">
                                            <li className="nav-item">
                                                <Link className="nav-link active" to='/withdraw'>Player Withdraw</Link>
                                            </li>
                                           
                                            </ul>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="tab-content">
                                            <div className="tab-pane active" id="link4">
                                                Verify player ID and withdraw confirmation code. Payout funds to physical player as requested.
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
    )
}

export default Dashboard;
