import "./PlayerDeposit.css";
import spinner from '../assets/spinner.png';
import { useState } from "react";
import { HttpService } from "../http/HttpService";
import { getHttpService } from "..";

const PlayerDeposit = (props) => {

console.log("Deposit currencies ", props.settings.currencies)

const [progress, setProgress] = useState({pending: false, success: false, failed: false, errorMessage: null, reference: null});

const onSubmitDeposit = (e) => {
       e.preventDefault();

       const {amount, currency, password, reference } = e.target.elements
       let request = {
           amount: amount.value,
           currency: currency.value,
           reference: reference.value,
           password: password.value,
           playerId: props.player.userId
       }

       console.log("Submit deposit ", request)
       setProgress({pending: true, missing: false, reference:null})

       getHttpService().post("/pos/player/deposit", request).then((data) => {
              console.log("Deposit response: ", data)
              setProgress({pending: false, error: false, success: true, reference: request.reference})
  
          }).catch((e)=>{
              console.log("Error ", e);
              setProgress({pending: false, success: false, error: true, errorMessage: e, reference:null})
          });
}

 return (
       <div className="card">
              <div className="card-header">
              <h5 className="card-category">Action</h5>
              <h3 className="card-title">Player Deposit</h3>
              </div>
              <div className="card-body">
                     <form onSubmit={onSubmitDeposit}>
                            <div className="row d-flex justify-content-center">
                                   <div className="col-sm-8 col-xs-12">
                                          

                                          {!progress.success && 
                                                 <div>
                                                        <div className="form-group">
                                                               <label>Amount to Deposit <span className="form-text text-muted">Make sure you receive the money before clicking on deposit</span></label>
                                                               <div className="row">
                                                                      
                                                                      <div className="col-sm-8 col-xs-12">
                                                                             <input id="amount" type="number" className="form-control" autoComplete="off"/>
                                                                      </div>

                                                                      <div className="col-sm-4 col-xs-12">
                                                                             <select id="currency" className="bootstrap-select dropdown-toggle btn btn-info" aria-label=".form-select-lg example">
                                                                                    {props.settings.currencies.map(function(name, index){
                                                                                           return <option key={index}>{name}</option>;
                                                                                    })}
                                                                             </select>
                                                                      </div>

                                                               </div>
                                                        </div>
                                                        <br/>
                                                        <div className="form-group">
                                                               <label>Password <span className="form-text text-muted">Enter your personal password</span></label>
                                                               <div className="row">
                                                                      <div className="col-12">
                                                                             <input id="password" type="password" className="form-control" autoComplete="off"/>
                                                                      </div>
                                                               </div>
                                                               
                                                        </div>

                                                        <br/>
                                                        <div className="form-group">
                                                               <label>Reference <span className="form-text text-muted">Optional - you can enter a reference for this payment</span></label>
                                                               <div className="row">
                                                                      <div className="col-12">
                                                                             <input id="reference" type="text" className="form-control" autoComplete="off"/>
                                                                      </div>
                                                               </div>
                                                               
                                                        </div>
                                                 </div>
                                          }


                                   </div>
                            </div>
                            <br/>
                            <div className="row d-flex justify-content-center">
                                   <div className="col-md-8">
                                          
                                          {progress.pending &&
                                                 <div className="row d-flex justify-content-center">
                                                        <img className="spinnerImage" src={spinner}/>
                                                 </div>
                                          }

                                          {progress.error && 
                                                 <div className="row d-flex justify-content-center">
                                                        <div className="alert alert-danger alert-with-icon" data-notify="container">
                                                               <span data-notify="icon" className="tim-icons icon-alert-circle-exc"></span>
                                                               <span data-notify="message">The request failed. See error message below and make sure your input is correct.<br/><br/>  
                                                               {progress.errorMessage} 
                                                               </span>
                                                        </div>
                                                 </div>
                                          }
                                          
                                          {progress.success && 
                                                 <div className="row d-flex justify-content-center">
                                                 
                                                        
                                                        <div className="card card-stats col-md-6 col-sm-8 col-xs-12">
                                                               <div className="card-body">
                                                                      <div className="row">
                                                                             <div className="col-2">
                                                                                    <div className="info-icon text-center icon-success">
                                                                                           <i className="tim-icons icon-check-2"></i>
                                                                                    </div>
                                                                             </div>
                                                                             <div className="col-10">
                                                                                    <div className="numbers">
                                                                                           <p className="card-category">Sucess</p>
                                                                                           <h3 className="card-title">DEPOSIT SENT!</h3>
                                                                                    </div>
                                                                             </div>
                                                                      </div>
                                                               </div>
                                                               
                                                               {progress.reference &&
                                                                      <div className="card-footer">
                                                                             <hr/>
                                                                             <div className="stats">
                                                                                    <i className="tim-icons icon-tag"></i> Reference: {progress.reference}
                                                                             </div>
                                                                      </div>
                                                               }
                                                        </div>
                                                                             
                                                 </div>
                                          }

                                          {!progress.pending && !progress.success &&
                                                 <ul className="nav nav-pills nav-pills-primary flex-column">
                                                        <li className="nav-item">
                                                               <input className="btn-primary btn-round depositButton nav-link active btn" type="submit" value="Deposit" />
                                                        </li>
                                                 </ul>
                                          }
                                                 
                                   </div>
                            </div>
                     </form>
              </div>
              <div className="card-footer"></div>
       </div>
 );


}

export default PlayerDeposit;