import { useState } from 'react';
import { Link } from 'react-router-dom';
import avatar from '../assets/avatar.png';
import { HttpService } from '../http/HttpService';
import "./Player.css";
import PlayerInfoBox from './PlayerInfoBox';
import spinner from '../assets/spinner.png';
import PlayerDeposit from './PlayerDeposit';

const ATR_HEADERS = {
    "address1": "Address 1",
    "address2": "Address 1",
    "idType": "Id Document Type",
    "idCode": "Id Document Code",
    "nationality": "Nationality",
    "country": "Country"
}

const PlayerDetails = (props) => {

    const renderAttributes = () => {
        const atrs = Object.entries(props.player?.attributes ?? {}).map(([key, value]) => {
            const header = ATR_HEADERS[key] ?? key
            return <PlayerInfoBox key={key} header={header} message={value} styleName='alert-info' icon='icon-molecule-40' bg='bg-default'/>
        })

        return <>{atrs}</> ;
    }


    return (
        <div>
        <div className="card card-user">
                <div className="card-body">
                    <div className="author">
                        <div className="block block-one"></div>
                        <div className="block block-two"></div>
                        <div className="block block-three"></div>
                        <div className="block block-four"></div>
                        <img className="avatar" src={avatar} alt="..." />
                        <h5 className="title">ID: {props.player.userUid}</h5>
                        <h2 className="white">
                        {props.player.firstName} {props.player.lastName} 
                        </h2>

                        {props.player.email && <PlayerInfoBox message={props.player.email} styleName='alert-info' icon='icon-email-85' bg='bg-info'/>}
                        {props.player.phone && <PlayerInfoBox message={props.player.phone} styleName='alert-info' icon='icon-mobile' bg='bg-info'/>}
                        {props.player.dateOfBirth && <PlayerInfoBox header="Date of Birth" message={props.player.dateOfBirth} styleName='alert-info' icon='icon-calendar-60' bg='bg-info'/>}
                        {props.player.created && <PlayerInfoBox header="Phone" message={props.player.created} styleName='alert-info' icon='icon-tap-02' bg='bg-info'/>}

                        {renderAttributes()}

                        {props.player.sameAffiliateId
                            ? <PlayerInfoBox message='Affiliated with you' styleName='alert-success' icon='icon-square-pin' bg='bg-success'/>
                            : <PlayerInfoBox message='Not affiliated with you' styleName='alert-warning' icon='icon-square-pin' bg='bg-warning'/>
                        }

                        
                        {props.player.kycVerified && <PlayerInfoBox message='Identity Verified' styleName='alert-success' icon='icon-badge' bg='bg-success'/>}
                    </div>
                   

                </div>
                    <div className="card-footer">
                </div>
              
            </div>

    </div>
    );

}

export default PlayerDetails;