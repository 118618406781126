import React, {useState} from 'react';
import "./Topbar.css";
import { useDispatch, useSelector } from 'react-redux';
import {Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from 'reactstrap';
import { handleLogoutRequest } from '../redux/sessionReducer';
import { HttpService } from '../http/HttpService';
import { getHttpService } from '..';

const Topbar = (props) => {
    const session = useSelector((state) => state.session);
    const dispatch = useDispatch();

    const doLogout = () => {
        dispatch(handleLogoutRequest());
        getHttpService().get("/public/logout").then((data) => {
            console.log("Logged out");
        }).catch((e)=>{
            console.log("Logout failed", e)
        });
    }

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-absolute navbar-transparent fixed-top" style={{top: '0px', transition: 'all 0.5s linear 0s'}}>
                <div className="container-fluid">
                    <div className="navbar-wrapper">                        
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item ">
                                <i className="tim-icons icon-single-02"></i> {session.user.name}
                            </li>
                        </ul>
                    </div>
                    
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item " >
                            <a href="#" onClick={doLogout} className="nav-link">
                                <i className="tim-icons icon-button-power"></i> Logout
                            </a>
                        </li>
                    </ul>
                    
                </div>
            </nav>
        </div>
    );
}

export default Topbar;
