import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import axios, {AxiosInstance} from "axios";
import { Provider } from "react-redux";
import  store  from './store';
import { HttpService } from './http/HttpService';


// Bootstrap theme is Black Dashboard Pro
// Link: https://demos.creative-tim.com/marketplace/black-dashboard-pro/examples/dashboard.html


let httpService;
let config;


(async function() {
    
    try {
        config = await getConfig()

    } catch (e) {
        let hostname = document.location.hostname;
        console.log("Hostname "+hostname);
        if(hostname.startsWith("10.0") || hostname.startsWith("192.168")) {
            config = { baseUrl : "http://" + hostname+":9085/rest"};
        } if(hostname == "nano") {
            config = { baseUrl : "https://admin.neobet.io/rest"}
        } else {
            config = { baseUrl :  "http://localhost:8095/rest",}
        }
    }
    console.log("Config ", config);
    setup(config);
    
    ReactDOM.render(
        <React.StrictMode>
            <BrowserRouter>
                <Provider store={store}>
                    <App/>
                </Provider>
            
            </BrowserRouter>
        </React.StrictMode>,
        document.getElementById('root')
    );
})();

export async function getConfig() {
    let http =axios.create({
        headers : {
            "Content-type" : "application/json"
        }
    });
    return http.get("/config/config.json").then( r => r.data);

}

export function getHttpService() {
    return httpService;
 };

export function setup() {
    httpService = new HttpService(config);
};


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();




