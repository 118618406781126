import { useState } from 'react';
import { Link } from 'react-router-dom';
import avatar from '../assets/avatar.png';
import { HttpService } from '../http/HttpService';
import "./Player.css";
import PlayerInfoBox from './PlayerInfoBox';
import spinner from '../assets/spinner.png';
import PlayerDeposit from './PlayerDeposit';
import PlayerDetails from './PlayerDetails';
import { getHttpService } from '..';
import { useSelector } from 'react-redux';

const Player = (props) => {

    const [progress, setProgress] = useState({pending: false});
    const session = useSelector((state) => state.session);

    const setPlayerVerified = () => {
         setProgress({pending: true})

         getHttpService().get("/pos/player/complete-kyc/"+props.player.userId).then(() => {
            setProgress({pending: false})
            props.reloadPlayer();

        }).catch((e)=>{
            console.log("Error ", e);
            setProgress({pending: false})
        });
        
    }

    return (
        <div>
            <PlayerDetails player={props.player} />

            {!props.player.kycVerified &&
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-category">Action</h5>
                            <h3 className="card-title">Identity Verification</h3>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="tab-content">
                                    <div className="tab-pane active" id="link1">
                                        Player is not yet verified. If you mark as verified (if available as an option) you acknowledge that you have verified the player's identification and copies are stored as required.
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <br/>

                            {session.user.kycEnabled ? 
                                <div className="row d-flex justify-content-center">
                                    <div className="col-md-8">
                                        <ul className="nav nav-pills nav-pills-primary flex-column">
                                        <li className="nav-item">
                                            <button className="nav-link active kycButton btn-success"onClick={setPlayerVerified}>Mark as Verified {progress.pending && <img className="spinnerImage" src={spinner}/>}</button>
                                        </li>
                                        </ul>
                                    </div>
                                </div>
                                :
                                <div className="row d-flex justify-content-center">
                                    <div className="col-md-8">
                                        <ul className="nav nav-pills nav-pills-primary flex-column">
                                        <li className="nav-item">
                                            <h3 className="card-title">Player identity verification required</h3>
                                        </li>
                                        </ul>
                                    </div>
                                </div>
                            }

                        </div>
                        <div className="card-footer"></div>
                    </div>
            }

            {props.player.kycVerified &&
                <PlayerDeposit player={props.player} settings={props.settings}/>
            }
    </div>
    );

}

export default Player;