import axios, {AxiosInstance} from "axios";
import React from 'react';
import { ROOT_URL } from './config';
import { settings } from './config';
import {handleBlocked, handleLoginBlocked, handleLoginError, handleLoginResponse, handleLogoutRequest} from "../redux/sessionReducer";
import store from "../store";

export class HttpService {
    
    axios;
    baseUrl;

    constructor(config) {
        this.baseUrl = config.baseUrl;
        console.log("HTTP Server Base URL ", this.baseUrl);
        this.axios = axios.create({
            headers : {
                "Content-type" : "application/json"
            }
        })
    }

    get(url) {
        return  new Promise((resolve, reject)=> {
            this.axios.get(this.baseUrl + url, { withCredentials : true })
                .then(response => resolve(response.data))
                .catch(e => this.handleError(url, e, reject))
        })
    }
    
    post(url, entity) {
        return  new Promise((resolve,reject)=> {
            this.axios.post(this.baseUrl + url,entity, { withCredentials : true })
                .then(response => resolve(response.data))
                .catch(e => this.handleError(url, e, reject))
        })
    }
    
    handleError(path, response, reject) {
        if (response.message.includes("403") || response.message.includes("401")) {
            store.dispatch(handleLogoutRequest());
            reject("Login failed");

        } else if (response.message.includes("428")) {
            console.log("428 error for path " + path + " with status " + response.status, response);
            reject("Bad password provided");

        } else if (response.message.includes("423")) {
            console.log("423 error for path " + path + ", message: " + response.response.data, response.response);
            store.dispatch(handleBlocked(""+response.response.data));
            reject(response.response.data);

        }else if (response.message.includes("417")) {
            console.log("417 error for path " + path + " with status " + response.status, response);
            reject("Payment is already handled or canceled");

        }else if (response.message.includes("406")) {
            console.log("406 error for path " + path + " with status " + response.status, response);
            reject(response.response.data);

        } else {
            console.log("Error for path " + path + " with status " + response.response.data, response.response);
            reject(response.message);
        }
    }
}