import React, {useReducer} from 'react';
import "./Deposit.css";
import { useSelector } from 'react-redux';

const Deposit = (props) => {

    const session = useSelector((state) => state.session);

    console.log("Dashboard Render - is logged in? ", session);
    return(
        <React.Fragment>
                <div className="wrapper wrapper-full-page ">        
                    <div className="full-page login-page ">
                        <div className="content">
                            <div className="container">
                                <div className="col-lg-8 col-md-8 ml-auto mr-auto">

                                    <h1>DEPOSIT PAGE</h1>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
    )
}

export default Deposit;
