import React from 'react';
import "./Login.css";
import image from '../../bootstrap/img/card-primary.png';
import {Redirect} from 'react-router-dom';
import {handleLoginError, handleLoginRequest, handleLoginResponse} from "../../redux/sessionReducer";
import {useDispatch, useSelector} from 'react-redux';
import {getHttpService} from '../..';

const Login = (props) => {
    const state = useSelector((state) => state);
    const session = useSelector((state) => state.session);
    const dispatch = useDispatch();

    const doLogin = (e) => {
        e.preventDefault();
        const {affiliateId, username, password } = e.target.elements
        let credentials = {
            username: username.value,
            affiliateId: affiliateId.value, 
            password: password.value}
        dispatch(handleLoginRequest());
        getHttpService().post("/pos/login", credentials).then((data) => {
            dispatch(handleLoginResponse(data));
        }).catch((e)=>{
            dispatch(handleLoginError(e));
        });
    }

    const addFocus = (e) =>{
        e.preventDefault();
       e.currentTarget.classList.add('input-group-focus');
    }
    
    
    const removeFocus = (e) =>{
        e.preventDefault();
        e.currentTarget.classList.remove('input-group-focus');
    }
    
    if(session.loggedIn === true) {
        return <Redirect to="/app" />
    }

    return (
        <React.Fragment>
            <div className="wrapper wrapper-full-page ">        
                <div className="full-page login-page ">
                    <div className="content">
                        <div className="container">
                            <div className="col-lg-4 col-md-6 ml-auto mr-auto">
                                <img className="logo" src={props.settings.logoUrl} alt=""/>
                                <form className="form" onSubmit={doLogin}>
                                    <div className="card card-login card-white">
                                        <div className="card-header">
                                            <img src={image} alt=""/>
                                            <h1 className="card-title">Agent</h1>
                                        </div>
                                        <div className="card-body">
                                            <div className="input-group" onFocus={addFocus} onBlur={removeFocus}>
                                                <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    <i className="tim-icons icon-world"></i>
                                                </div>
                                                </div>
                                                <input type="text" className="form-control" id="affiliateId" placeholder="Affiliate ID"/>
                                            </div>
                                            <div className="input-group" onFocus={addFocus} onBlur={removeFocus}>
                                                <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    <i className="tim-icons icon-badge"></i>
                                                </div>
                                                </div>
                                                <input type="text" className="form-control" id="username" placeholder="Username"/>
                                            </div>
                                            <div className="input-group" onFocus={addFocus} onBlur={removeFocus}>
                                                <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    <i className="tim-icons icon-lock-circle"></i>
                                                </div>
                                                </div>
                                                <input type="password" placeholder="Password" id="password" className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <input className="btn btn-primary btn-lg btn-block mb-3" type="submit" value="Login" />
                                            {session.error && <span className="text-danger">{session.error}</span>}
                                            {session.blocked && <span className="text-danger">{session.msg}</span>}
                                            
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Login;
