export const initialState = {
    loggedIn : false,
    inProgress : false,
    error : null, 
    user : null
}

export const sessionReducer = (state = initialState, action) => {
    console.log("handle sessionReducer action = ", action);
    switch(action.type) {
        case 'login-response':
            return { loggedIn : true, inProgress : false,  blocked: false, user : action.user }
        case "login-request":
            return { inProgress : false, loggedIn : false, error: false, blocked: false, user: null }
        case "logout-request":
            return { inProgress: false, error : action.error, blocked: false, loggedIn : false }
        case "login-error":
            return { inProgress: false, error : action.error, blocked: false, loggedIn : false }
        case 'logout':
            return { loggedIn : false, blocked: false, user : null};
        case 'blocked':
                return { loggedIn : false, blocked: true, user : null, error: action.error, msg: action.msg};
        default:
            return state;
    }
};

export const handleLogoutRequest = () => {
    return { type : 'logout-request'};
}
export const handleLoginResponse = (user) => {
    return { type : 'login-response', user : user};
}
export const handleLoginRequest = () => {
    return { type : "login-request"};
}
export const handleLoginError = (error) => {
    return { type : "login-error", error : error}
} 
export const handleBlocked = (error) => {
    return { type : "blocked", msg : error}
}


