import "./PlayerInfoBox.css";

const PlayerInfoBox = (props) => {
    
    const header = props.header


    return (
       <div className="row d-flex justify-content-center">
              <div className="col-md-10 cool-sm-12 input-group larger">
                     <div className="input-group-prepend">
                            <div id="propIcon" className={`iconArea input-group-text ${props.bg}`}>
                                   <i id="keyIcon" className={`keyIcon tim-icons ${props.icon}`}></i>
                            </div>
                     </div>
                     <label type="text" id="valueLabel" className="form-control text-left" placeholder="Not Set">
                            {header  &&  <span className="text-muted">{props.header}:</span>} {props.message}
                     </label>
              </div>
       </div>
    );


}

export default PlayerInfoBox;